<template>
  <div class="bgcard">
    <div class="searchlist">
      <div class="select_b" style="margin-right: 0.2rem">
        <span style="color: #bbbbbb">{{ $t("lang.orderstatus") }}：</span>
        <el-select style="height: 0.4rem; width: 1.5rem" v-model="value1" :placeholder="$t('lang.Please')" popper-class="select_a"
          @change="stauts" clearable>
          <el-option :label="$t('lang.whole')" value=""></el-option>
          <el-option :label="$t('lang.obligation')" value="WAIT_PAY"></el-option>
          <el-option :label="$t('lang.lostefficacy')" value="INVALID"></el-option>
          <el-option :label="$t('lang.Completed')" value="COMPLETE"></el-option>
          <el-option :label="$t('lang.canceled')" value="CANCELLED"></el-option>
        </el-select>
      </div>
      <div class="searchtime" style="margin-right: 0.4rem">
        <span style="color: #bbbbbb">{{ $t("lang.time") }}：</span>
        <el-date-picker
          v-model="time"
          type="daterange"
          :range-separator="$t('lang.to')"
          :start-placeholder="$t('lang.Startdate')"
          :end-placeholder="$t('lang.Enddate')"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          @change="picker"
        >
        </el-date-picker>
      </div>
      <div class="query">
        <div class="inputname questiontwo">
          <input
            v-model="parsm.keyword"
            :placeholder="$t('lang.odertexts')"
          />
        </div>
        <div class="searchbtn">
          <button @click="sear">
            <p style="color: #b2b2b2">{{ $t("lang.search") }}</p>
          </button>
        </div>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column
          prop="id"
          :label="$t('lang.oderId')"
          align="center"
					width="150"
        >
          <template #default="scope">
            <div style="position: relative">
              <span class="texts">{{ scope.row.id }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.ordernumber')"
          align="center"
          show-overflow-tooltip
					width="230"
        >
          <template #default="scope">
            <span class="texts">{{ scope.row.sn}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.ordertype')" align="center" width="150">
          <template #default="scope">
            <span v-if="scope.row.orderType == 0">{{$t('lang.Balancerecharges')}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="userName"
          :label="$t('lang.bankNumber')"
          align="center"
					width="180"
        >
        </el-table-column>
        <el-table-column :label="$t('lang.creationTime')" align="center" width="200">
          <template #default="scope">
            <span v-if="scope.row.createTime">{{ scope.row.createTime }}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.timeofpayment')" align="center" width="200">
          <template #default="scope">
            <span v-if="scope.row.payTime">{{ scope.row.payTime }}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.rechargeamount')" align="center" width="180">
					<template slot-scope="scope">
						<span>{{scope.row.amount |monsa}}</span>
					</template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.patternofpayment')"
          align="center"
					width="180"
        >
        <template #default="scope">
            <span v-if="scope.row.payType === 0">{{$t('lang.Wechatpayment')}}</span>
            <span v-if="scope.row.payType === 1">{{$t('lang.Alipaypayment')}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          :label="$t('lang.orderstatus')"
          align="center"
					width="150"
        >
        <template #default="scope">
            <span v-if="scope.row.orderStatus=='WAIT_PAY'">{{$t('lang.obligation')}}</span>
            <span v-if="scope.row.orderStatus=='INVALID'">{{$t('lang.lostefficacy')}}</span>
            <span v-if="scope.row.orderStatus=='COMPLETE'">{{$t('lang.Completed')}}</span>
            <span v-if="scope.row.orderStatus=='CANCELLED'">{{$t('lang.canceled')}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="display: flex; align-items: center"
        v-if="tableData.length > 0"
      >
        <div class="allNum">{{ $t("lang.total") }}: {{ tallsize }}</div>
        <el-pagination
          background
          :current-page="parsm.page"
          layout="prev, pager, next"
          :total="tallsize"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as order from "@/api/order";
export default {
  data() {
    return {
      seenFreetext: this.$t("lang.details"),
      time: "",
      value1: "",
      tableData: [],
      tallsize: 0,
      parsm: {
        page: 1,
        size: 10,
        stime: "",
        etime: "",
        keyword: "",
        orderStatus:"",
      },
    };
  },
  mounted() {
    this.getList();
  },
	filters:{
		monsa(val){
			if(val){
				return val.toFixed(2)
			}else{
				return val
			}
		}
	},
  methods: {
    // 搜索
    sear() {
      this.parsm.page = 1;
      this.getList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //时间筛选
    picker(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      if (e) {
        this.parsm.stime = e[0];
        this.parsm.etime = e[1];
      } else {
        this.parsm.stime = "";
        this.parsm.etime = "";
      }
      this.getList();
    },
    //状态筛选
    stauts(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.orderStatus = e;
      this.getList();
    },
    //获取订单列表
    async getList() {
      let res= await order.getorderlist(this.parsm)
      if (res.data) {
        this.tableData = res.data.content;
        this.tallsize = res.data.totalElements
      }
    }
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: relative;
}
.messige {
  position: absolute;
  width: 0.1rem;
  height: 0.1rem;
  background: red;
  top: 0.05rem;
  left: 0.2rem;
  border-radius: 50%;
}
.texts {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
